import React, { useRef, useEffect, useState, useContext } from "react";
import { Button, Col, Row, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Panel from "../components/Panel";
import api from "../utils/api";
import { toast } from "react-toastify";
import TransactionHistory from "../components/TransactionHistory";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { numberFormatBRL } from "../components/InvestmentsSummary";
import { useTranslation, Trans } from 'react-i18next';

export const setAmount = (refParam, amount, selectedCoin) => {
  var value = amount.includes(",") ? amount.replace(",", ".") : amount;
  if (!value) {
    value = "";
  }

  if (!refParam?.current) {
    return amount;
  }

  var smallestUnit = selectedCoin === "bitcoin" ? "0.000001" : "0.01";

  if (value.includes(".")) {
    var decimal = value.split(".")[1];
    if (decimal.length <= (selectedCoin === "bitcoin" ? 6 : 3)) {
      value = value.split(".")[0] + "." + value.split(".")[1].slice(0, (selectedCoin === "bitcoin" ? 6 : 3));
      refParam.current.value = value;
      if (value.split(".")[1] === "000000") {
        value = value.split(".")[0];
      }
      return value;
    } else {
      value = value.split(".")[0] + "." + value.split(".")[1].slice(0, (selectedCoin === "bitcoin" ? 6 : 3));
      if (value === "0.000000") {
        value = smallestUnit;
      }
      if (value.split(".")[1] === "000000") {
        value = value.split(".")[0];
      }
      refParam.current.value = value;
      return value;
    }
  }

  if (value.length > 2) {
    if (value == 0 || value === "0") {
      refParam.current.value = smallestUnit;
      return smallestUnit;
    }
  }

  if (value.length >= 5) {
    value = value.slice(0, 4);
    refParam.current.value = value;
    return value;
  }

  if (refParam.current) {
    refParam.current.value = value;
  }

  return value;
};

function SendCrypto() {
  const navigate = useNavigate();
  const amountInput = useRef(null);
  const addressInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [inputedAmount, setInputedAmount] = useState("0");
  const [selectedCoin, setSelectedCoin] = useState("bitcoin");

  const messageRequired = "Por favor, preencha este campo.";

  const { btcPrice, usdtPrice, fees } = useContext(AppContext);

  const { t } = useTranslation();



  const sendCryptoSchema = yup.object().shape({
    crypto: yup.string().required(messageRequired),
    destinationAddress: yup
      .string()
      .required(messageRequired)
      .test(
        "minLength",
        t("minlength"),
        (val) => val.length >= 10
      )
      .test(
        "maxLength",
        t("maxlength"),
        (val) => val.length <= 80
      )
      .test(
        "address-validate", // Name
        t("addressvalidate"), // Msg
        async (address) => {
          if (!address) {
            return false;
          }

          if (address.length < 10) {
            return false;
          }

          setLoading(true);
          let isValid = false;

          if (selectedCoin === "bitcoin") {
            const res = await api.post("node/validateAddress", {
              address,
            });
            isValid = res.data;
          } else {
            // Regex for validating Ethereum address
            const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;
            isValid = ethAddressRegex.test(address);
          }

          setLoading(false);
          return isValid;
        }
      ),
    amount: yup
      .string()
      .required(messageRequired)
      .test(
        "minimum-amount",
        selectedCoin === "bitcoin" ? t("minamountbtc") : t("minamount"), // "O valor mínimo é de 0.000001 (100 satoshi)",
        (val) => selectedCoin === "bitcoin" ? val >= 0.000001 : val >= 0.001
      )
      .test(
        "maxLength",
        selectedCoin === "bitcoin" ? t("maxamountlengthbtc") : t("maxamountlength"), //"O valor máximo é de 13 caracteres.",
        (val) => selectedCoin === "bitcoin" ? val.length <= 13 : val.length <= 5
      )
      .test(
        "minLength",
        t("minamountlength"), // "O valor mínimo é de 1 caractere.",
        (val) => val.length >= 1
      ),
    twoFactorAuth: yup
      .string()
      .required(messageRequired)
      .test("valid-code", t("invalidcode"), (val) => {
        if (/^[0-9]{6}$/.test(val)) {
          return true;
        }

        if (val === "") {
          return false;
        }

        return false;
      }),
  });

  const handleInputChange = (e) => {
    let { value } = e.target;

    value = value.replace(/[^0-9.,]/g, "");
    value = value.replace(/,/g, ".");

    const dotIndex = value.indexOf(".");
    if (dotIndex !== -1) {
      const beforeDot = value.slice(0, dotIndex);
      const afterDot = value.slice(dotIndex + 1).replace(/\./g, "");
      value = `${beforeDot}.${afterDot}`;
    }
    setInputedAmount(value);
  }

  const setAddress = (address) => {
    var value = address;
    if (!value) {
      value = "";
    }

    if (value.length > 80) {
      value = address.slice(0, 80);
      if (addressInput.current) {
        addressInput.current.value = value;
      }
      return value;
    }

    if (addressInput.current) {
      addressInput.current.value = value;
    }

    return value;
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sendCryptoSchema),
  });
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await api.post("node/send", {
        crypto: selectedCoin,
        amount: data.amount,
        destinationAddress: data.destinationAddress,
        twoFactorAuth: data.twoFactorAuth,
      });

      const resData = res.data;

      if (resData.ok == true) {
        setLoading(false);
        toast.success(t("successtx"), /* `Transação enviada com sucesso!` */ {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        navigate("/panel/confirmations?id=" + resData.data.id);
      }

      if (resData.ok == false) {
        setLoading(false);
        toast.error(resData.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (resData.error) {
        setLoading(false);
        toast.error(t("errortx"),/* `Erro ao enviar a transação.` */ {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(
        ` ${t("errortx")} : ${e.message ?? e.response?.data?.message
        }`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  const { ref: amountRHFRef, ...theArgs } = register("amount");
  const { ref: addressRHFRef, ...restArgs } = register("destinationAddress");

  useEffect(() => {
    setValue("crypto", selectedCoin);
    setValue("amount", "0");
    setValue("destinationAddress", "");
    setValue("twoFactorAuth", "");
  }, [selectedCoin]);

  return (
    <Panel page="send" title={t("sendb")}>
      <Row>
        <Col
          style={{
            minWidth: 300,
            maxWidth: 350,
          }}
          md
        >
          <Form onSubmit={handleSubmit(onSubmit)} className="card p-3 mb-4">
            <Row className="text-center mb-2">
              <Col>
                <h3>{t("sendb")}</h3>
              </Col>
            </Row>
            <Form.Group className="text-center mt-2 mb-4">
              <select
                value={selectedCoin}
                onChange={(e) => setSelectedCoin(e.target.value)}
                className="form-control input"
                style={{ width: "100%" }}
              >
                <option value="bitcoin">Bitcoin</option>
                {/* <option value="usdt">USDT</option> */}
              </select>
              {errors.crypto && (
                <span className="invalid-feedback show">
                  {errors.crypto.message}
                </span>
              )}
              <Form.Label className="mt-4">{t("qty")} {/* Quantidade */} </Form.Label>
              <div className="input-group">
                <input
                  className="input append form-control"
                  name="amount"
                  type="text"
                  minLength={1}
                  maxLength={selectedCoin === "bitcoin" ? 13 : 5}
                  {...register("amount", {
                    setValueAs: (value) => setAmount(amountInput, value),
                  })}
                  ref={(e) => {
                    amountRHFRef(e);
                    amountInput.current = e;
                  }}
                  // onChange={(e) => setInputedAmount(e.target.value)}
                  onChange={(e) => handleInputChange(e)}
                />
                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                    {selectedCoin === "bitcoin" ? "BTC" : selectedCoin.toUpperCase()}
                  </span>
                </div>
              </div>
              <div style={{ fontWeight: "400", fontStyle: "italic", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                <span>{t("refference")} (BRL): </span>
                <span>
                  {inputedAmount > 0
                    ? selectedCoin === "bitcoin"
                      ? numberFormatBRL(inputedAmount * btcPrice)
                      : numberFormatBRL(inputedAmount * usdtPrice)
                    : `R$ 0,00`}
                </span>
              </div>
              {errors.amount && (
                <span className="invalid-feedback show">
                  {errors.amount.message}
                </span>
              )}
            <Form.Label className="mt-4">
                {selectedCoin === "bitcoin" ? t("btcaddressto") : t("usdtaddressto")}
              </Form.Label>
              <input
                className="input form-control"
                type="text"
                name="destinationAddress"
                {...register("destinationAddress", {
                  setValueAs: (value) => setAddress(value),
                })}
                ref={(e) => {
                  addressRHFRef(e);
                  addressInput.current = e;
                }}
              />
              {errors.destinationAddress && (
                <span className="invalid-feedback show">
                  {errors.destinationAddress.message}
                </span>
              )}
              <Form.Label className="mt-4">{t("auth2FA")}</Form.Label>
              <Form.Control
                className="input"
                name="twoFactorAuth"
                {...register("twoFactorAuth", { required: true })}
              />
              {errors.twoFactorAuth && (
                <span className="invalid-feedback show">
                  {errors.twoFactorAuth.message}
                </span>
              )}
            </Form.Group>
            <div className="orderbook-fees order-container">
              <table>
                <thead>
                  <tr>
                    <th colSpan="3" className="orderbook-title">{selectedCoin === "bitcoin" ? t("btcfee") : t("withdrawfee")} {/* Tarifa de Transferência de BTC */}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("taxes")}:</td>
                    <td>
                      {selectedCoin === "bitcoin"
                        ? `${fees[3]?.value} ${fees[3]?.currency}`
                        : `${fees[4]?.value} ${fees[4]?.currency}`}
                    </td>
                  </tr>
                  {/*
                <tr>
                  <td>Valor da Tarifa(BTC):</td>
                  { fees[3]?.currency === '%' ? (
                    <td>BTC { Math.abs(((inputedAmount) * (Number(fees[3]?.value) / 100)).toFixed(8)) }</td>
                  ) : (
                    <td>BTC { Math.abs(((inputedAmount) - (Number(fees[3]?.value))).toFixed(8)) }</td>
                  )}
                </tr>
                  */}
                  <tr>
                    <td>{t("refference")} (BRL):</td>
                    {fees[3]?.currency === '%' ? (
                      <td>{selectedCoin === "bitcoin" ? numberFormatBRL(Math.abs((btcPrice * inputedAmount) * (Number(fees[3]?.value) / 100))) : numberFormatBRL(Math.abs((usdtPrice * inputedAmount) * (Number(fees[3]?.value) / 100)))}</td>
                    ) : (
                      <td>{selectedCoin === 'bitcoin' ? numberFormatBRL((btcPrice * (Number(fees[3]?.value)))) : numberFormatBRL((usdtPrice * (Number(fees[4]?.value))))}</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
            <Form.Group className="text-center mt-4">
              <Form.Label className="mt-4">{t("totalsendvalue")} {/* Total Enviado (Valor - Tarifas) */}</Form.Label>
              <div className="input-group">
                {fees[3]?.currency === '%' ? (
                  <input
                    className="input append form-control"
                    name="totalAmountValue"
                    value={selectedCoin === "bitcoin" ? (inputedAmount - ((inputedAmount) * (Number(fees[3]?.value) / 100))).toFixed(8) : (inputedAmount - ((inputedAmount) * (Number(fees[4]?.value) / 100))).toFixed(3)}
                    disabled
                  />
                ) : (
                  <input
                    className="input append form-control"
                    name="totalAmountValue"
                    value={selectedCoin === "bitcoin" ? (inputedAmount - (Number(fees[3]?.value))).toFixed(8) : (inputedAmount - (Number(fees[4]?.value))).toFixed(4)}
                    disabled
                  />
                )}
                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                    {selectedCoin === "bitcoin" ? "BTC" : selectedCoin.toUpperCase()}
                  </span>
                </div>
              </div>
              <div style={{ fontWeight: "400", fontStyle: "italic", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                <span> {t("refference")} (BRL): </span>
                {fees[3]?.currency === '%' ? (
                  <span>
                    {inputedAmount > 0 ?
                      selectedCoin === "bitcoin" ?
                        numberFormatBRL((inputedAmount * btcPrice) - ((inputedAmount * btcPrice) * (Number(fees[3]?.value) / 100))).toFixed(8)
                        : numberFormatBRL(((inputedAmount * usdtPrice) - ((inputedAmount * usdtPrice) * (Number(fees[4]?.value) / 100))).toFixed(3))
                      : `R$ 0,00`}
                  </span>
                ) : (
                  <span>
                    {inputedAmount > 0 ?
                      selectedCoin === "bitcoin" ?
                        numberFormatBRL((inputedAmount * btcPrice) - (Number((fees[3]?.value) * btcPrice)).toFixed(8))
                        : numberFormatBRL((inputedAmount * usdtPrice) - (Number((fees[4]?.value) * usdtPrice)).toFixed(3))
                      : `R$ 0,00`}
                  </span>
                )}
              </div>
            </Form.Group>

            {!loading ? (
              <div className="text-center mt-3 mb-3">
                <Button type="submit">{t("sendb")}</Button>
              </div>
            ) : (
              <div className="text-center mt-3 mb-3">
                <Spinner animation="border" variant="secondary" />
              </div>
            )}
          </Form>
        </Col>
        <TransactionHistory category="send" selectedCoin={selectedCoin} />
      </Row>
    </Panel>
  );
}

export default SendCrypto;
