import { createContext, useEffect, useState } from "react";
import { getUserDataFromJWT } from "../utils/auth";
import api, { microservice } from "../utils/api";

export const AppContext = createContext();

export function AppContextProvider({ children }) {
  const [loggedInUser, setLoggedInUser] = useState(undefined);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [modalApproveBTC, setModalApproveBTC] = useState(false);
  const [nodeURL, setNodeURL] = useState('');
  const [approvedTP, setApprovedTP] = useState(false);
  const [approveBTCSelectedRow, setApproveBTCSelectedRow] = useState(false);
  const [modalBuyBTC, setModalBuyBTC] = useState(false);
  const [modalType, setModalType] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [nodeTransactions, setNodeTransactions] = useState([]);
  const [legalPerson, setLegalPerson] = useState(null);
  const [balances, setBalances] = useState([]);
  const [trade, setTrade] = useState(null);
  const [hasInvestmentActive, setHasInvestmentActive] = useState(false);
  const [countNotifications, setCountNotifications] = useState(0);
  const [countNodeTransactions, setCountNodeTransactions] = useState(0);
  const [countPendingStatus, setCountPendingStatus] = useState([]);
  const [levelPermissionSelected, setLevelPermissionSelected] = useState();
  const [userSelected, setUserSelected] = useState({});
  const [photoId, setPhotoId] = useState(null);
  const [btcPrice, setBtcPrice] = useState([]);
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [showModalTermsTrade, setShowModalTermsTrade] = useState(false);
  const [reloadWithdraws, setReloadWithdraws] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showInvestmentModal, setShowInvestmentModal] = useState(false);
  const [showInvestmentWithdrawModal, setShowInvestmentWithdrawModal] = useState(false);
  const [showInvestmentWithdrawProfitModal, setShowInvestmentWithdrawProfitModal] = useState(false);
  const [showRentabilityModal, setShowRentabilityModal] = useState(false);
  const [showModalCreateProfitabily, setShowModalCreateProfitabily] =
    useState(false);
    const [initialDate, setInitialDate] = useState("00/00/0000");
    const [finalDate, setFinalDate] = useState("00/00/0000");

  const [investmentAmount, setInvestmentAmount] = useState(null);
  const [investmentPeriod, setInvestmentPeriod] = useState("3");

  const [investment, setInvestment] = useState(null);
  const [allInvestments, setAllInvestments] = useState(null);

  const [showModalEditProfitabily, setShowModalEditProfitabily] = useState({
    show: false,
    id: "",
  });
  const [balanceBTC, setBalanceBTC] = useState();
  const [balanceUSDT, setBalanceUSDT] = useState();
  const [availablesMonthsOfContract, setAvailablesMonthsOfContract] = useState(
    []
  );

  const [prices, setPrices] = useState({ usd: "", btc: "" });
  const [fees, setFees] = useState([]);
  const [modalKYC, setModalKYC] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [hasInvestmentPermission, setHasInvestmentPermission] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);
  const [completeUser, setCompleteUser] = useState({});

  async function getUsdtPrice() {
    try {
      let req = await api.get(`prices`);
      if (req.error) return;
  
      let prices = req.data;

      prices.forEach((price) => {
        if (price.symbol === "USD" && price.base === "BRL") {
          setUsdtPrice(price.price);
        }
      });

    } catch (e) { }
  }

  const getPrices = async (date) => {
    let req = await api.get(`prices/${date}`);
    if (req.error) return;

    return req.data;
  };


  async function returnPrices() {
    try {
      let { usdPrice, btcPrice } = await getPrices(new Date().toISOString());
   
      setPrices((prevState) => ({
        ...prevState,
        usd: usdPrice,
        btc: btcPrice,
      }));
    } catch (e) { }
  }

  
  useEffect(() => {
    fetchMaintenanceStatus();
    setNodeURL(process.env.REACT_APP_POLYGON_WS_NODE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    returnPrices();
    getUsdtPrice();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btcPrice, usdtPrice]);

  const userObj = getUserDataFromJWT();

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    currentPage: 1,
    lastPage: 1,
  });

  const [timerFinished, setTimerFinished] = useState(false);

  const fetchMaintenanceStatus = async () => {
    try {
      const response = await api.get('maintenance/status');
      if (response && response.data && response.data.active !== undefined) {
        setMaintenanceMode(response.data.active);
      }
    } catch (error) {
      console.error("Erro ao buscar status de manutenção:", error);
    }
  };
  
  const getBalances = (usersid) => {
    api.get(`balances?filter[where][usersid]=${usersid}`).then((req) => {
      if (!req.error) {
        setBalances(req.data);
      }
    });
  };

  const getAllInvestments = async (userId) => {
    microservice.get(`users/investments/${userId}`).then((req) => {
      if (req.error === "Investment not found") {
        setAllInvestments([]);
      }
      else {
        setAllInvestments(req);
      }
    });
  };

  const getSeeInvestment = async (userId) => {
    api.get(`seeInvestment/${userId}`).then((req) => {
      if (req.error) {
        setHasInvestmentPermission(false);
      }
      else {
        setHasInvestmentPermission(req.data.message);
        // return req.data.message;
      }
    });
  };

  function getInvestment() {
    api.get("my-investments?filter[where][status]=active").then((req) => {
      if (!req.error) {
        setTrade(req.data.data.find((el) => el.type === "trade"));
      }
    });
  }

  async function getFees() {
    try {
      const response = await api.get("fees/serviceFees");
      const data = response.data;
      setFees(data);
    } catch (error) {
     console.log(error) 
    }
  };

  return (
    <AppContext.Provider
      value={{
        userObj,
        getBalances,
        loggedInUser,
        setLoggedInUser,
        modalBuyBTC,
        setModalBuyBTC,
        modalApproveBTC,
        setModalApproveBTC,
        approveBTCSelectedRow,
        setApproveBTCSelectedRow,
        modalType,
        setModalType,
        notifications,
        setNotifications,
        nodeTransactions,
        setNodeTransactions,
        legalPerson,
        setLegalPerson,
        balances,
        setBalances,
        trade,
        setTrade,
        hasInvestmentActive,
        setHasInvestmentActive,
        countNotifications,
        setCountNotifications,
        countNodeTransactions,
        setCountNodeTransactions,
        countPendingStatus,
        setCountPendingStatus,
        levelPermissionSelected,
        setLevelPermissionSelected,
        userSelected,
        setUserSelected,
        pagination,
        setPagination,
        photoId,
        setPhotoId,
        btcPrice,
        setBtcPrice,
        usdtPrice,
        setUsdtPrice,
        getInvestment,
        showModalTermsTrade,
        setShowModalTermsTrade,
        agreeTerms,
        setAgreeTerms,
        showInvestmentModal,
        setShowInvestmentModal,
        showModalCreateProfitabily,
        setShowModalCreateProfitabily,
        availablesMonthsOfContract,
        setAvailablesMonthsOfContract,
        showModalEditProfitabily,
        setShowModalEditProfitabily,
        getAllInvestments,
        allInvestments,
        setAllInvestments,
        investment,
        setInvestment,
        investmentAmount,
        setInvestmentAmount,
        investmentPeriod,
        setInvestmentPeriod,
        balanceBTC,
        setBalanceBTC,
        setBalanceUSDT,
        initialDate,
        setInitialDate,
        finalDate,
        setFinalDate,
        timerFinished,
        setTimerFinished,
        prices,
        setPrices,
        returnPrices,
        getPrices,
        showInvestmentWithdrawModal,
        setShowInvestmentWithdrawModal,
        showInvestmentWithdrawProfitModal,
        setShowInvestmentWithdrawProfitModal,
        showRentabilityModal,
        setShowRentabilityModal,
        reloadWithdraws,
        setReloadWithdraws,
        fees,
        setFees,
        getFees,
        selectedUser,
        setSelectedUser,
        hasInvestmentPermission,
        setHasInvestmentPermission,
        getSeeInvestment,
        modalKYC,
        setModalKYC,
        checkedTerms,
        setCheckedTerms,
        checkedPrivacy,
        setCheckedPrivacy,
        approvedTP,
        setApprovedTP,
        completeUser,
        setCompleteUser,
        maintenanceMode,
        setMaintenanceMode,
        nodeURL,
        setNodeURL
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
