import React from "react";
import { Card, Form, Button, Container } from "react-bootstrap";
import Particles from "react-particles-js";
import HeaderBar from "../components/HeaderBar";
import BottomBar from "../components/BottomBar";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";
import api from "../utils/api";
import Terms from "../components/Terms";
import Privacy from "../components/Privacy";
import TermsEN from "../components/TermsEN";
import PrivacyEN from "../components/PrivacyEN";
import { useNavigate } from "react-router-dom";
import CurrencyWidget from "../components/CurrenciesWidget";
import { AppContext } from "../context/AppContext";
import { withTranslation } from 'react-i18next';

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      firstTrySubmit: false,
      referralCode: '',
      errors: {
        name: "",
        email: "",
        confirmEmail: "",
        phone: "",
        pass: "",
        confirmPass: "",
        token: "",
        terms: "",
      },
      form: {
        name: "",
        email: "",
        confirmEmail: "",
        phone: "",
        pass: "",
        confirmPass: "",
        token: "",
        terms: "",
        tradingName: "",
        ppe: false,
        legalPerson: false,
        referredBy: "",
      },
      validations: {
        name: this.validateName,
        tradingName: this.validateTradingName,
        email: this.validateEmail,
        confirmEmail: this.validateConfirmEmail,
        phone: this.validatePhone,
        pass: this.validatePass,
        confirmPass: this.validateConfirmPass,
        token: this.validateToken,
        terms: this.validateTerms,
        ppe: this.validatePpe
      },
    };
  }

  static contextType = AppContext;

  componentDidMount() {
    const referralCode = localStorage.getItem("referralCode");
    if (referralCode && referralCode !== '') {
      //  this.fetchReferredBy(referralCode);
    }
  }

  fetchReferredBy = async (referralCode) => {
    try {
      const response = await api.get(`users/referral/${referralCode}`);
      const referredBy = response.data.name;
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          referredBy: referredBy
        },
        referralCode: referralCode
      }));
    } catch (error) {
      console.error("Error fetching referred by username:", error);
    }
  };

  recaptchaOnChange = (value) => {
    this.handleChange({
      target: {
        type: "text",
        name: "token",
        value: value,
      },
    });
  };

  validateName = (value) => {
    if (value !== "") {
      return "";
    } else {
      return this.props.t("fullname");
    }
  };

  validateTradingName = (value) => {
    if (value !== "") {
      return "";
    } else {
      return this.props.t("companyname");
    }
  };

  validateEmail = (value) => {
    if (/^.+@(\w+\.\w+)+$/.test(value)) {
      return "";
    } else {
      return this.props.t("invalidemail");
    }
  };

  validateConfirmEmail = (value) => {
    if (this.state.form.email === value && value !== "") {
      return "";
    } else {
      return this.props.t('notmatchemail');
    }
  };

  validatePhone = (value) => {
    value = value
      .replace("(", "")
      .replace(")", "")
      .replace("-", "")
      .replace(" ", "")
      .replace("_", "");
    if (/^[0-9]{9,13}$/.test(value)) {
      return "";
    } else {
      return this.props.t("invalidphone");
    }
  };

  validatePass = (value) => {
    if (/^.{6,100}$/.test(value)) {
      return "";
    } else {
      return this.props.t("invalidpass");
    }
  };

  validateConfirmPass = (value) => {
    if (this.state.form.pass === value && value !== "") {
      return "";
    } else {
      return this.props.t("passnotmatch");
    }
  };

  validateToken = (value) => {
    if (value !== null && value !== "") {
      return "";
    } else {
      return this.props.t("validatetoken");
    }
  };

  validateTerms = (value) => {
    if (value) {
      return "";
    } else {
      return this.props.t("needterms");
    }
  };

  validatePpe = (value) => {
    if (value) {
      return "";
    } else {
      return this.props.t("needppe");
    }
  };



  handleChange = (event) => {
    let form = { ...this.state.form };
    let errors = { ...this.state.errors };
    let validations = this.state.validations;

    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    form[event.target.name] = value;


    // If it's the first try submit and it's a checkbox
    if (!this.state.firstTrySubmit && event.target.type === "checkbox") {
      // Perform validations here if necessary
      errors[event.target.name] = validations[event.target.name](value);

      if (event.target.name === "terms" && (!this.context.checkedTerms && !this.context.checkedPrivacy)) {
        this.handleCheckboxClick();
      }
    } else if (!this.state.firstTrySubmit) {
      errors[event.target.name] = validations[event.target.name](value);
    }

    // Update the state with new form and errors
    this.setState({ form, errors });
  };

  handleCheckboxClick = () => {
    if (!this.context.checkedTerms && !this.context.checkedPrivacy) {
      this.terms.show();
      this.privacy.close();
    }
  };

  handlePpeChange = (event) => {
    let value = event.target.checked;
    this.setState({ form: { ...this.state.form, ppe: value } });
  };

  handleTermsClick = () => {
    this.terms.show();
  };

  handlePrivacyClick = () => {
    this.privacy.show();
  };

  onSubmit = async (event) => {
    event.preventDefault();

    let form = this.state.form;
    let errors = this.state.errors;
    let validations = this.state.validations;
    let formParams = Object.keys(form);
    let foundError = false;

    formParams = formParams.filter((el) => el !== "tradingName" && el !== "referredBy" && el !== "legalPerson");

    // console.log("formParams ", formParams);

    formParams.forEach((key) => {
      // console.log("KEY > ", key);

      if (key != "tradingName" || key != "legalPerson" || key != "referredBy" || key != "referralCode") {
        
        let error = validations[key](form[key]);

        errors[key] = error;

        if (error !== "") {
          foundError = true;
        }
      }
    });

    if (!foundError) {

      let req = await api.post("signup", {
        name: form.name,
        email: (form.email).toLowerCase(),
        phone: form.phone,
        password: form.pass,
        recaptcha: form.token,
        legalPerson: form.legalPerson,
        ppe: form.ppe,
        tradingName: form.tradingName,
        locale: this.props.i18n.resolvedLanguage.toString(),
        // referralCode: this.state.referralCode !== '' ? this.state.referralCode : ""
      });

      if (!req.error) {
        this.props.navigate("/panel/confirm?email=" + form.email);
      }
    } else {
      this.setState({ errors, firstTrySubmit: true });
    }
  };

  render() {
    const { t, i18n } = this.props;
    const currentLanguage = i18n.resolvedLanguage;
    const TermsComponent = currentLanguage === 'en' ? TermsEN : Terms;
    const PrivacyComponent = currentLanguage === 'en' ? PrivacyEN : Privacy;

    return (
      <Container className="p-0 d-table" fluid>
        <HeaderBar hide hide_buttons />
        <div
          className=""
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            padding: 0,
            margin: 0,
            zIndex: -1,
            opacity: 0.5,
          }}
        >
          <Particles
            style={{
              height: "100%",
            }}
            width="100%"
            height="100%"
            params={{
              fps_limit: 28,
              particles: {
                color: "rgba(0, 0, 0, 1)",
                links: {
                  color: "rgba(0, 0, 0, .7)",
                },
                move: {
                  speed: 0.5,
                },
                number: {
                  value: 50,
                },
                size: {
                  value: 3,
                },
              },
            }}
          />
        </div>
        <Container
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            paddingTop: 30,
            paddingBottom: 50,
          }}
          className="d-table-row"
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: 30,
              paddingBottom: 50,
            }}
          >
            <div
              style={{
                paddingTop: "auto",
                paddingBottom: "auto",
              }}
            >
              <Card
                className="sign-card"
                style={{
                  maxWidth: 500,
                  margin: "auto",
                  marginTop: 30,
                  width: "90%",
                }}
              >
                <Card.Title
                  style={{
                    fontWeight: "bold",
                    fontSize: 25,
                  }}
                >
                  {this.props.t('registration')}
                </Card.Title>
                <Card.Body className="pb-0">
                  <Form onSubmit={this.onSubmit}>
                    <div
                      className="d-flex mb-4"
                      style={{ justifyContent: "space-evenly" }}
                    >
                      <Form.Check
                        inline
                        label={this.props.t('person')}
                        name="legalPerson-pf"
                        type="radio"
                        id="radio-legalPerson-pf"
                        checked={!this.state.form.legalPerson}
                        onChange={() =>
                          this.setState({
                            form: { ...this.state.form, legalPerson: false },
                          })
                        }
                      />
                      <Form.Check
                        inline
                        label={this.props.t('jperson')}
                        name="legalPerson-pj"
                        type="radio"
                        id="radio-legalPerson-pj"
                        checked={this.state.form.legalPerson}
                        onChange={() =>
                          this.setState({
                            form: { ...this.state.form, legalPerson: true },
                          })
                        }
                      />
                    </div>

                    <Form.Group>
                      <Form.Control
                        className="input"
                        name="name"
                        type="text"
                        value={this.state.form.name}
                        onChange={this.handleChange}
                        required
                        isInvalid={this.state.errors.name}
                        placeholder={this.props.t('fulln')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {this.state.form.legalPerson && (
                      <Form.Group>
                        <Form.Control
                          className="input"
                          name="tradingName"
                          type="text"
                          value={this.state.form.tradingName}
                          onChange={this.handleChange}
                          required
                          isInvalid={this.state.errors.tradingName}
                          placeholder={this.props.t('fantasyname')}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.errors.tradingName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}

                    <Form.Group>
                      <Form.Control
                        className="input"
                        name="email"
                        type="email"
                        value={this.state.form.email}
                        onChange={this.handleChange}
                        required
                        isInvalid={this.state.errors.email}
                        placeholder="E-mail"
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        className="input"
                        name="confirmEmail"
                        type="email"
                        value={this.state.form.confirmEmail}
                        onChange={this.handleChange}
                        required
                        isInvalid={this.state.errors.confirmEmail}
                        placeholder={this.props.t('confirmemail')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.confirmEmail}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        as={InputMask}
                        mask="(99) 99999-9999"
                        placeholder={this.props.t('phone')}
                        className="input"
                        name="phone"
                        type="text"
                        value={this.state.form.phone}
                        isInvalid={this.state.errors.phone}
                        onChange={this.handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        className="input"
                        name="pass"
                        type="password"
                        value={this.state.form.pass}
                        onChange={this.handleChange}
                        required
                        isInvalid={this.state.errors.pass}
                        placeholder={this.props.t('pass')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.pass}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        className="input"
                        name="confirmPass"
                        type="password"
                        value={this.state.form.confirmPass}
                        onChange={this.handleChange}
                        required
                        isInvalid={this.state.errors.confirmPass}
                        placeholder={this.props.t('confirmpass')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.confirmPass}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {this.state.form.referredBy && (
                      <Form.Group>
                        <Form.Label>{this.props.t('referralCheck')}</Form.Label>
                        <Form.Control
                          className="input"
                          name="referredBy"
                          type="text"
                          value={this.state.form.referredBy}
                          readOnly
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="text-left pl-4">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={this.recaptchaOnChange}
                      />
                      <Form.Label className="text-danger">
                        {this.state.errors.token}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Check
                        className="text-left ml-4"
                        type="checkbox"
                        id="check-ppe"
                      >
                        <Form.Check.Input
                          name="terms"
                          type="checkbox"
                          checked={this.state.form.ppe}
                          onChange={this.handlePpeChange}
                          required
                          isInvalid={this.state.errors.terms}
                        />
                        <Form.Check.Label>
                          <span>
                            {this.props.t('notppeconfirm')}
                          </span>
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                    <Form.Group>
                      <Form.Check
                        className="text-left ml-4"
                        type="checkbox"
                        id="check-terms"
                      >
                        <Form.Check.Input
                          name="terms"
                          type="checkbox"
                          checked={this.context.checkedTerms && this.context.checkedPrivacy ? true : false}
                          onChange={this.handleChange}
                          required
                          isInvalid={this.state.errors.terms}
                        />
                        <Form.Check.Label>
                          {this.props.t('readandconfirm')}
                          <span
                            className="clickable"
                            // onClick={() => this.terms.show()}
                            onClick={this.handleTermsClick}
                          >
                            {this.props.t('terms')}
                          </span>{" "}
                          {this.props.t('and')}{" "}
                          <span
                            className="clickable"
                            // onClick={() => this.privacy.show()}
                            onClick={this.handlePrivacyClick}
                          >
                            {this.props.t('ppolicy')}
                          </span>{" "}
                          {this.props.t('service')}
                        </Form.Check.Label>
                        <Form.Check.Label>
                          {this.props.t('concurregister')}
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                    <Button className="btn-primary mt-2 mb-3" type="submit">
                      {this.props.t('send')}
                    </Button>
                  </Form>
                  <span className="bold">
                    {this.props.t('alreadyclient')} <a href="/panel/signin">{this.props.t('enter')}</a>
                  </span>
                </Card.Body>
              </Card>
            </div>
          </div>
          {this.state.showPopup && (
            <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title"> {this.props.t('termswarningtitle')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" onClick={() => this.setState({ showPopup: false })}>&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.props.t('termsWarning')}
                  </div>
                  <span
                    className="clickable"
                    // onClick={() => this.terms.show()}
                    onClick={this.handleTermsClick}
                  >
                    {this.props.t('terms')}
                  </span>{" "}
                  {this.props.t('and')}{" "}
                  <span
                    className="clickable"
                    // onClick={() => this.privacy.show()}
                    onClick={this.handlePrivacyClick}
                  >
                    {this.props.t('ppolicy')}
                  </span>{" "}
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => this.setState({ showPopup: false })}> {this.props.t('close')}</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Container>
        <BottomBar />
        <TermsComponent ref={ref => this.terms = ref} />
        <PrivacyComponent ref={ref => this.privacy = ref} />
      </Container>
    );
  }
}

const TranslatedSignup = withTranslation()(Signup);

export default (props) => <TranslatedSignup {...props} navigate={useNavigate()} />;